@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,700');
body{
  background: #000; 
  font-family: 'Roboto', sans-serif; 
  color: #fff;
}
.loadingWindow{
  position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
 
}
.container{
  width: 150px;
  height: 10px;
  border: 2px solid #b2b2b2;
  border-radius: 7px;
  margin: 0 auto;
  padding: 2px 1px;
  overflow: hidden;
  font-size: 0;
}
.box{
  width: 9px;
  height: 100%;
  background: linear-gradient(to bottom, #2838c7 0%,#5979ef 17%,#869ef3 32%,#869ef3 45%,#5979ef 59%,#2838c7 100%);
  display: inline-block;
  margin-right: 2px;
  animation: loader 2s infinite;
  animation-timing-function: linear;
}
.logo{
  width: 220px;
  margin: 50px auto;
}
.logo p{
  margin: 0;
  padding: 0;  
}
.top{
  font-size: 16px;
  font-weight: 300;
  line-height: 16px;
}
.top:after{
  content:"\00a9";
  font-size: 10px;
  position: relative;
  top: -5px;
  margin-left: 2px;
}
.mid{
  font-size: 46px;
  font-weight: 700;
  line-height: 36px;  
}
.mid span{
  font-size: 22px;
  display: inline-block;
  vertical-align: top;
  color: #FF6821;
  margin-top: -8px;
}
.logo .bottom{
  font-size: 30px;
  font-weight: 300;
  line-height: 30px;
  margin-left: 5px;
  
}
@keyframes loader{
  0%{
    transform: translate(-30px);
  }
  100%{
    transform: translate(150px);
  }
}
