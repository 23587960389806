*{
    font-family: "Segoe UI", serif;
}

.TextEditorApp
{
    background: #fdfdfd;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.editor
{
    display: flex;
    flex-direction: column;
    color: #000;
}

body
{
    background: #f3f3ef;
    margin:0;
}


.container-TextEditor .ql-editor
{
    width: 100%;
    min-height: 11in;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .5);
    background: #fff;
    color: #000;
}

.container-TextEditor .ql-container.ql-snow
{
    border:none;
    display: flex;
    justify-content: center;
}