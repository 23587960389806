*,
:before,
:after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.ui_layout{
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    width:auto;
    height:auto;
    overflow:hidden;
    padding:0;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.fillspace, .fullscreen{
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    width:100%;
    height:100%;
    display:block;
    margin:0;
}

.ui_layout>section{
    position: relative;
    overflow: auto;
    -webkit-box-flex:1;
    -ms-flex:1;
    flex:1;
}

.noscroll{
    overflow: hidden!important;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary{
    display:block;
}

.desktop_files{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: auto;
    height: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.desktop_files .desktop_explorer{
    -webkit-box-flex:1;
    -ms-flex:1;
    flex:1;
    overflow-y:auto;
}

.desktop_explorer-workspace{
    overflow-x:hidden;
    overflow-y:hidden!important;
    position: relative;
}

img{
    border:0;
}

.ui_elipsis{
    white-space: nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
}

.window_head_title{
    -webkit-box-flex:1;
    -ms-flex:1;
    flex:1;
    width:0;
}

.desktop_explorer-details>.ui_icon, .desktop_explorer-column>.ui_icon, .desktop_explorer-list>.ui_icon, .desktop_explorer-workspace>.ui_icon, .desktop_explorer-workspace-full>.ui_icon, .desktop_explorer-icons>.ui_icon{
    position: relative;
    text-align: center;
    display: inline-block;
    vertical-align: top;
    height: 60px;
    width: 72px;
    z-index: 0;
    line-height: 12px;
    margin-bottom: 2px;
    padding-top: 5px;
    padding-left: 1px;
}

.window_body-flex {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    overflow: auto;
    height: 1%;
}

.window_body {
    padding: 5px;
    position: relative;
    overflow: auto;
    outline: 0 none;
}

.window_body>iframe{
    position:absolute;
    display:block;
    width: 100%;
    height:100%;
}

iframe{
    border:0 none;
    background: transparent;
}

.window{
    border: 1px solid #555;
    background: #ccc;
    position: absolute;
    padding: 3px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 100px;
    min-height: 100px;
    z-index: 1000;
}

.ui_layout>header, .ui_layout>footer{
    -webkit-box-flex: 0;
    -ms-flex: 0 auto;
    flex:0 auto;
}

.pa0{
    padding:0;
}

.relative{
    position: relative;
}

#s64_start{
    margin-left:0;
    margin-right:0;
}

#s64_start, .window_docked{
    height:22px;
    margin-left:3px;
    margin-bottom: 1px;
    padding-bottom:0;
}

button, input, select{
    border-radius: 0px;
}

#s64_start *{
    position:relative;
    top:-1px;
}

#s64_start *, .window_docked{
    vertical-align: middle;
}

button>img{
    vertical-align: middle;
}

#s64_dock{
    overflow-x:auto;
}

.combo_one>.combo_one_main{
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.window_dock{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

#s64_notif{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0 5px;
    padding-top: 3px;
}

#s64_clock{
    width: 5ch;
}

#s64_notif>*{
    height:16px;
    padding-right:5px;
}

.active{
    border: 2px dashed lightblue;
}