*, *::before, *::after{
  font-family: "Segoe UI";
  cursor: url("./Cursors/normal-Cursor.cur"), default;
  box-sizing: border-box;

}

#s64_clock
{
  color: black;
}

.StylizedButton
{
  cursor: url("./Cursors/pointerCursor.cur"), pointer;
}

#doorOSSys
{
  position: absolute; left: 0px; top: 0px; z-index: 0;
}

#perlinAppIcon
{
  position: absolute; left: 75px; top: 110px; z-index: 0;
}

#aboutMeIcon
{
  position: absolute; left: 0px; top: 110px; z-index: 0;
}

#gameIcon
{
  position: absolute; left: 0px; top: 170px; z-index: 0;
}

#calculator
{
  position: absolute; left: 75px; top: 170px; z-index: 0;
}

#textEditor
{
  position: absolute; left: 150px; top: 170px; z-index: 0;
}

#applicationWindow
{
  position:absolute; top: 67px; left: 0; z-index: 1001; height: 463px; width: 400px; box-sizing: border-box;
}


