*
{
    font-family: "Segoe UI";
}

.grid
{
    width: 90%;
    margin: auto;
    display:grid;
    grid-template-columns: repeat(5, 80px);
    grid-template-rows: repeat(8, 80px);
    gap:5px;

}

.CalculatorDisplay
{
    background: #fdfdfd;
    color: #000000;
    border: 1px solid #7e9cb8;
    text-align: right;
    font-size: 36px;
    grid-column-start: span 0;
    grid-column-end: span 5;
    height: 100%;
    width: 100%;
    padding: 30px 24px;

}

.StylizedButton
{
    font-size: 24px;
    font-family: "Segoe UI", serif;
    color: #0000fd;
    border-radius: 5px;
    background: #f3f3ef;
    border: 1px solid #003c73!important;
}

.StylizedMathButton
{
    color: #fd1111;
}

.StylizedButton:hover
{
    background: #a6a6a2;
    cursor: url("../Cursors/pointerCursor.cur"), pointer;
}

.CalculatorSkin
{
    display: flex;
    flex-direction: column;
    background: silver;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-content: center;

}
