html, body, button, input, select, option, textarea {
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 12px;
}

.window-active .window_head, .skin_bg{
    background: #2673a2;
}

#s64_screen{
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;

}

#loading{
    display: block;
    position: absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    z-index: 5000;
    width: 100vw;
    height:100vh;
}

.desktop_explorer>.ui_icon{
    color:#deeeff;
    text-shadow: #000 0 1px;
}

.window{
    padding-right:2px;
    padding-left:3px;
    padding-top:3px;
    padding-bottom:2px;
    box-shadow: 1px 0 #000, 0 1px #000, 1px 1px #000, inset 1px 1px #fff;
}

.menu-bar .menu_submenu ul, .menu-menu ul, .menu-menu .menu_submenu ul, .skin_outset_deep, .window{
    border-top:1px solid silver;
    border-left: 1px solid #c0c0c0;
}

.btn, button:not(.unstyled), input[type=reset], input[type=submit], input[type=button], .window, .menu--bar .menu_submenu ul, .menu-menu ul, .menu-menu .menu_submenu ul{
    color: #000;
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
    border-right: 1px solid gray;
    border-bottom: 1px solid gray;
    box-shadow: inset 1px 1px #dfdfdf,1px 0 #000,0 1px #000,1px 1px #000;
    background-color: silver;
}

.window-active .window_head{
    background: #4682b4;
}

.window_head{
    color: #fff;
    padding: 2px 2px 1px 2px;
    margin-bottom: 2px;
    display: flex;
}

.window_docked_icon, .window_head_icon{
    width: 16px;
    height:16px;
}

#s64_start span, .window_head_title, .window_docked_text{
    padding-left: 5px;
    height: 16px;
}

.cursor_move, .window-draggable{
    cursor: move;
}

.btn, button:not(.unstyled), input[type=reset], input[type=submit], input[type=button]{
    line-height: 16px;
    padding: 1px 6px;
    overflow: hidden;
}

.window_head_pin, .window_head_config, .window_head_reload, .window_head_help, .window_head_minimize, .window_head_maximize, .window_head_maximized, .window_head_close{
    font-size: 8px;
    width: 14px;
    height: 14px;
    line-height: 14px!important;
    margin-left: 3px;
    text-align: center;
    padding: 0;
    padding-bottom: 1px;
    position: relative;
    cursor: pointer;
}

.window_head_minimize::before{
    content:"_";
    font-size: 100%;
    font-weight: bolder;
}

.skin_light, .alert-help .alert_text{
    background: #fff;
}

.skin_inset{
    border-top: 1px solid gray;
    border-left: 1px solid gray;
    box-shadow: 1px 0 #fff, 0 1px #fff, 1px 1px #fff;
    background-color: #b8b8b8;
    margin-right: 1px;
    margin-bottom: 1px;
}

input, textarea, .window_body, .ui_selectable{
    -webkit-touch-callout: default;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.window_body-with_iframe {
    padding: 0;
}

#s64_taskbar{
    padding:2px;
    padding-top:3px;
    margin:0;
}

.skin_base{
    background: silver;
}

.skin_outset_deep{
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
    border-right: 1px solid gray;
    border-bottom: 1px solid gray;
    box-shadow: inset 1px 1px #dfdfdf,1px 0 #000,0 1px #000,1px 1px #000;
    background-color: silver;
    margin-right: 1px;
    margin-bottom: 1px;
}

.combo_one {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
}

#s64_start span, .window_head_title, .window_docked_text {
    padding-left: 5px;
    height: 16px;
}

h1, h3, h4, b, strong, th, .bold {
    font-weight: 400!important;
    text-shadow: -1px 0;
    letter-spacing: 1px;
}

.btn:active:not(:disabled), button:not(.unstyled):active:not(:disabled), input[type=reset]:active:not(:disabled), input[type=submit]:active:not(:disabled), input[type=button]:active:not(:disabled), .btn.active, button:not(.unstyled).active, input[type=reset].active, input[type=submit].active, input[type=button].active, .btn.pressed, button:not(.unstyled).pressed, input[type=reset].pressed, input[type=submit].pressed, input[type=button].pressed, .btn.selected, button:not(.unstyled).selected, input[type=reset].selected, input[type=submit].selected, input[type=button].selected, .btn.always-pressed, button:not(.unstyled).always-pressed, input[type=reset].always-pressed, input[type=submit].always-pressed, input[type=button].always-pressed {
    border-top: 1px solid #000;
    border-left: 1px solid #000;
    border-right: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    box-shadow: inset 1px 1px gray,1px 0 #fff,0 1px #fff,1px 1px #fff;
}

#s64_start, .ui_window_docked {
    height: 22px;
    margin-left: 3px;
    margin-bottom: 1px;
    padding-bottom: 0;
}

.maximized{
    top:0;
    left:0;
    right:0;
    bottom:0;
    height:100%;
    width:100%;
}